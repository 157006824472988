import React, { useEffect, useState } from "react";
import axios from "axios";
import { Url } from "../../function/FunctionApi";

const SelectWorkOrderType = ({ value, onChange }) => {
  const [workOrderTypes, setWorkOrderTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWorkOrderTypes = async () => {
      try {
        const response = await axios.get(`${Url}WorkOrderType`);
        if (response.status === 200 && response.data.data) {
          setWorkOrderTypes(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching work order types:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkOrderTypes();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-start w-[48%]">
      <label htmlFor="workOrderType">نوع أمر العمل</label>
      <select
        id="WorkOrderType"
        name="WorkOrderType"
        value={value}
        onChange={onChange}
        required
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
      >
        <option value="" disabled>
          اختر نوع أمر العمل
        </option>
        {workOrderTypes.map((type) => (
          <option key={type.id} value={type.name}>
            {type.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectWorkOrderType;
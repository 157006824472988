import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import UploadIcon from "../../Image/UploadIcon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import { Url } from "../../function/FunctionApi";
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";
import SelectConsultant from "../../Component/SelectConsultant/SelectConusltant";
import SelectDistrict from "../../Component/SelectDistrict/SelectDistrict";
import SelectContractor from "../../Component/SelectContractore/SelectContractore";

const createUrl = `${Url}PrivateProject/CreatePrivateProject`;
const updateUrl = `${Url}PrivateProject/update`;

const RadioGroup = ({ label, options, name, selectedValue, onChange }) => (
  <div className="violations">
    <h4>{label}</h4>
    <div className="radio-group">
      {options.map((option) => (
        <label key={option.value}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={
              selectedValue === (option.value === "exists" ? true : false)
            }
            onChange={onChange}
          />
          <span className="radio-circle" />
          {option.label}
        </label>
      ))}
    </div>
  </div>
);

const Form = ({ userData, apiData }) => {
  const [searchParams] = useSearchParams();

  const office = searchParams.get("office");

  const [formData, setFormData] = useState({
    ProjectPlace: "",
    Customer: "",
    ProjectName: "",
    TimeOfProject: "",
    projectValue: "",
    Office: office || "",
    Consultant: "",
    Note: "",
    SafetyViolationsExist: "",
    EstimatedValue: "",
    ActualValue: "",
    ExtractNumber: "",
    OrderDate: "", // New field
    StationNumber: "", // New field
    District: "", // New field
    Contractor: "", // New field
  });

  const [fileData, setFileData] = useState({
    ModelPhotos: [],
    SitePhotos: [],
    SafetyWastePhotos: [],
  });

  const fileInputRefs = {
    ModelPhotos: useRef(null),
    SitePhotos: useRef(null),
    SafetyWastePhotos: useRef(null),
  };

  const [errorMessage, setErrorMessage] = useState("");
  const token = userData?.token;
  const [showModal, setShowModal] = useState({ success: false, error: false });
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (apiData) {
      setFormData({
        ProjectPlace: apiData.projectPlace || "",
        ProjectName: apiData.projectName || "",
        Customer: apiData.customer || "",
        Office: apiData.office || "",
        TimeOfProject: apiData.timeOfProject || "",
        projectValue: apiData.projectValue || "",
        District: apiData.district || "",
        Consultant: apiData.consultant || "",
        Resources: apiData.resources || "",
        Note: apiData.note || "",
        SafetyViolationsExist: apiData.safetyViolationsExist,
        EstimatedValue: apiData.estimatedValue,
        ActualValue: apiData.actualValue,
        OrderDate: apiData.orderDate.split("T")[0] || "",
        StationNumber: apiData.stationNumber,
        District: apiData.district,
        Contractor: apiData.contractor,
        ExtractNumber: apiData.extractNumber,
      });
      setFileData({
        ModelPhotos: apiData.modelPhotos || [],
        SitePhotos: apiData.sitePhotos || [],
        SafetyWastePhotos: apiData.safetyWastePhotos || [],
      });
    }
  }, [apiData]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prev) => ({
      ...prev,
      [name]:
        name === "SafetyViolationsExist"
          ? value === "exists"
            ? true
            : false
          : value,
    }));
  };

  const openFileSelector = (fileType) => {
    fileInputRefs[fileType].current.click();
  };

  const handleFileChange = (event, fileType) => {
    const uploadedFiles = Array.from(event.target.files);
    const newFiles = uploadedFiles.filter((file) => {
      if (file.type === "application/pdf") {
        return true;
      }
      return file.size <= 1 * 1024 * 1024 * 1024;
    });
    const rejectedFiles = uploadedFiles.filter((file) => {
      return (
        file.type !== "application/pdf" && file.size > 1 * 1024 * 1024 * 1024
      );
    });

    if (rejectedFiles.length) {
      setErrorMessage("بعض الملفات كانت كبيرة جدًا ولا يمكن رفعها.");
    } else {
      setErrorMessage("");
    }

    setFileData((prev) => ({
      ...prev,
      [fileType]: [...prev[fileType], ...newFiles],
    }));
  };

  const handleFileDelete = (fileType, fileToDelete) => {
    setFileData((prev) => ({
      ...prev,
      [fileType]: prev[fileType].filter(
        (file) => file.name !== fileToDelete.name
      ),
    }));
  };
  const handleSubmit = async (isArchive) => {
    let fieldErrors = [];
    if (formData.ProjectPlace === "") fieldErrors.push("مكان المشروع");
    if (formData.Customer === "") fieldErrors.push("اسم العميل");
    if (formData.ProjectName === "") fieldErrors.push("اسم المشوع");
    if (formData.TimeOfProject === "") fieldErrors.push("مده التنفيذ");
    // if (formData.StationNumber === "") fieldErrors.push(" رقم المحطه");
    if (formData.District === "") fieldErrors.push("  الحي");
    if (formData.WorkDescription === "") fieldErrors.push(" وصف المشروع");
    // if (formData.OrderDate === "") fieldErrors.push("تاريخ التنفيذ");

    if (formData.Contractor === "") fieldErrors.push(" المقاول");
    if (formData.SafetyViolationsExist === "")
      fieldErrors.push("هل اخطاء السلامه موجوده ");
    if (fileData.ModelPhotos.length === 0) fieldErrors.push("المتسندات ");

    if (fieldErrors.length > 0) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        html: `يرجى ملء الحقول التالية: <br> ${fieldErrors.join(", ")}`,
      });
      return;
    }
    fieldErrors = [];
    const submissionData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      submissionData.append(key, value);
    });

    submissionData.append("isArchive", isArchive);

    Object.entries(fileData).forEach(([key, files]) => {
      files.forEach((file) => {
        submissionData.append(key, file);
      });
    });

    setLoading(true);
    setUploadProgress(0);

    try {
      const url = apiData ? updateUrl : createUrl;
      const method = apiData ? "PUT" : "POST";

      console.log(submissionData);
      await axios({
        method,
        url,
        data: submissionData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
      if (apiData) {
        setSuccessMessage("تم تحديث الطلب بنجاح.");
      } else {
        setSuccessMessage("تم إرسال الطلب بنجاح.");
      }
      setShowModal({ success: true, error: false });
    } catch (error) {
      console.error(error);
      let errorMessage =
        error.response?.data?.message || "يرجي  التاكد من البيانات";

      if (error?.response?.data?.errors) {
        const errors = error.response.data.errors;
        const errorMessages = Object.values(errors).flat().join(", ");
        errorMessage = errorMessages || errorMessage;
      } else if (error?.response?.data?.data) {
        errorMessage = error.response.data.data;
      }

      setErrorMessage(errorMessage);

      setShowModal({ success: false, error: true });
    } finally {
      setLoading(false);
    }
  };

  const refreshPage = () => {
    setFormData({
      ProjectPlace: "",
      Customer: "",
      ProjectName: "",
      TimeOfProject: "",
      projectValue: "",
      OrderDate: "",
      StationNumber: "",
      District: "",
      Contractor: "",

      Consultant: "",
      Note: "",
      SafetyViolationsExist: "",
      EstimatedValue: "",
      ActualValue: "",
      ExtractNumber: "",
    });
    setFileData({
      ModelPhotos: [],
      SitePhotos: [],
      SafetyWastePhotos: [],
    });
    window.location.reload();
  };
  const renderUploadSection = (
    label,
    fileType,
    buttonLabel,
    multiple = true
  ) => (
    <div className="upload-section">
      <h4>{label}</h4>
      <div className="upload-box">
        <span className="upload-icon">
          <img src={UploadIcon} alt="Upload" />
        </span>
        <p>{buttonLabel}</p>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, fileType)} // Add files to the state
          multiple={multiple}
          ref={fileInputRefs[fileType]}
          style={{ display: "none" }}
          accept=".jpg,.jpeg,.png,.pdf"
        />
        <div className="button-group">
          <button
            type="button"
            className="upload-button"
            onClick={() => openFileSelector(fileType)}
          >
            رفع ملف
          </button>
        </div>
      </div>

      {/* Render the file list */}
      <div className="file-list">
        {fileData[fileType]?.map((file, index) => (
          <div key={index} className="file-item">
            {file instanceof File ? (
              file.type === "application/pdf" ? (
                <embed
                  src={URL.createObjectURL(file)} // Show local PDF
                  type="application/pdf"
                  width="100%"
                  height="500px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(file)} // Show local image
                  alt="Uploaded file"
                  className="uploaded-image"
                />
              )
            ) : // Render files from the server
            file.url?.endsWith(".pdf") ? (
              <embed
                src={
                  file.url.startsWith("http")
                    ? file.url
                    : `https://www.rasmconsult.com/${file.url}`
                }
                type="application/pdf"
                width="100%"
                height="500px"
              />
            ) : (
              <img
                src={
                  file.url.startsWith("http")
                    ? file.url
                    : `https://www.rasmconsult.com/${file.url}`
                }
                alt="Uploaded file"
                className="uploaded-image"
              />
            )}
            <button
              className="delete-button"
              onClick={() =>
                file instanceof File
                  ? handleFileDelete(fileType, file)
                  : handleApiFileDelete(fileType, file.id)
              }
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );

  const handleApiFileDelete = async (fileType, fileId) => {
    const endpointMap = {
      ModelPhotos: "PrivateProject/model-photo",
      SitePhotos: "PrivateProject/site-photo",
      SafetyWastePhotos: "PrivateProject/safety-photo",
    };

    const endpoint = `${Url}${endpointMap[fileType]}?photoId=${fileId}`;
    try {
      const response = await axios.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setFileData((prev) => ({
          ...prev,
          [fileType]: prev[fileType].filter((item) => item.id !== fileId),
        }));
        Swal.fire({
          position: "center",
          icon: "success",
          title: "تم حذف الصوره بنجاح.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "فشل حذف الصورة",
        text: "يرجى المحاولة مرة أخرى.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }; 
  
  return (
    <div className="form-container" dir="rtl">
      <div className="container">
        <div className="FormData">
          <div sx={{ textAlign: "center", marginBottom: "30px" }}>
            <h3>بيانات الطلب</h3>
            <p>Get a Quote Immediately Upon Form Submission</p>
          </div>

          {[
            ["مكان المشروع", "اسم العميل"],
            ["مده التنفيذ", "اسم المشروع"].reverse(),
            ["القيمة المقدره", "الاستشاري"],
            ["تاريخ الطلب", "رقم المحطة"], // Added OrderDate and StationNumber
            ["المنطقة", "المقاول"], // Added District and Contractor
          ].map((pair, index) => (
            <div key={index} className="input-group">
              {pair.map((placeholder, idx) => {
                const fieldName = [
                  "ProjectPlace",
                  "Customer",
                  "ProjectName",
                  "TimeOfProject",
                  "projectValue",
                  "Consultant",
                  "OrderDate",
                  "StationNumber",
                  "District",
                  "Contractor",
                ][index * 2 + idx];

                if (fieldName === "Consultant") {
                  return (
                    <SelectConsultant
                      key={idx}
                      value={formData[fieldName]}
                      onChange={handleChange}
                    />
                  );
                } else if (fieldName === "District") {
                  return (
                    <SelectDistrict
                      officeName={'all'}
                      value={formData.District}
                      onChange={handleChange}
                    />
                  );
                } else if (fieldName === "Contractor") {
                  return (
                    <SelectContractor
                      value={formData[fieldName]}
                      onChange={handleChange}
                    />
                  );
                }

                return (
                  <div key={idx} className="groub_fe">
                    <label>{placeholder}</label>
                    <input
                      type={fieldName === "OrderDate" ? "date" : "text"}
                      name={fieldName}
                      placeholder={placeholder}
                      value={formData[fieldName] || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>
                );
              })}
            </div>
          ))}

          {renderUploadSection(
            "المستندات ",
            "ModelPhotos",
            "تصوير مستندات مع الختم"
          )}
          {renderUploadSection("صور الموقع", "SitePhotos", "صور الموقع")}

          <RadioGroup
            label="مخالفات السلامة"
            options={[
              { label: "يوجد", value: "exists" },
              { label: "لا يوجد", value: "not-exists" },
            ]}
            name="SafetyViolationsExist"
            selectedValue={formData.SafetyViolationsExist}
            onChange={handleChange}
          />

          {formData.SafetyViolationsExist &&
            renderUploadSection(
              "صور مخالفات السلامة",
              "SafetyWastePhotos",
              "صور المخالفات"
            )}

          <div className="input-group">
            <textarea
              name="Note"
              placeholder="الملاحظات"
              value={formData.Note}
              onChange={handleChange}
            />
          </div>

          <div className="button-group">
            <button
              className={`submit-button px-4 py-2 rounded ${
                loading ? "bg-gray-400 cursor-not-allowed" : "bg-green-600"
              } text-white`}
              onClick={() => handleSubmit(false)}
              disabled={loading}
            >
              {loading ? "جاري التحميل..." : "تم التنفيذ"}
            </button>
            <button
              type="button"
              className={`submit-button px-4 py-2 rounded ${
                loading ? "bg-gray-400 cursor-not-allowed" : "bg-green-600"
              } text-white`}
              style={{ background: "rgba(244, 67, 54, 1)", color: "white" }}
              onClick={() => handleSubmit(true)}
              disabled={loading}
            >
              {loading ? "جاري التحميل..." : "تحت التنفيذ"}
            </button>
          </div>
        </div>
      </div>
      <Modal show={loading} onHide={() => setLoading(false)}>
        <Modal.Header>
          <Modal.Title>جارٍ رفع البيانات</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>جاري رفع البيانات، الرجاء الانتظار...</p>
          <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
        </Modal.Body>
      </Modal>

      <Modal show={showModal.success} onHide={() => refreshPage()}>
        <Modal.Header closeButton>
          <Modal.Title>نجاح</Modal.Title>
        </Modal.Header>
        <Modal.Body>{successMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => refreshPage()}>
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModal.error}
        onHide={() => setShowModal({ error: false, success: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>خطأ</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModal({ error: false, success: false })}
          >
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Form;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Banner from "../../Component/SubmitApplicationComp/Banner";
import Form from "./Form";
import EngChangesRequest from "../../Component/EngChangesRequest/EngChangesRequest";

import img from "../../Image/Rectangle.png";
import { fetchDataWithRetries, Url } from "../../function/FunctionApi";

export default function MainPage({ userData }) {
  const { id } = useParams();
  const projectId = id ? Number(id) : null; 

  const [apiData, setApiData] = useState(null);
  
  const [changesData, setChangesData] = useState(null);
  const [loading, setLoading] = useState(!!projectId); 
  const [loadingChanges, setLoadingChanges] = useState(!!projectId);
  const [error, setError] = useState(null);
  const [errorChanges, setErrorChanges] = useState(null);


  useEffect(() => {
    if (!projectId || !userData?.token) return; // Skip if adding a new project

    const fetchProjectData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${Url}OperationsAndMaintenaceRequest/filter-orders`, {
          headers: { Authorization: `Bearer ${userData.token}` },
        });

        console.log("API Response:", response.data);

        const project = response.data.find((item) => item.id === projectId);
        setApiData(project || null);
      } catch (err) {
        setError("حدث خطأ أثناء جلب البيانات. الرجاء المحاولة لاحقًا.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [projectId, userData]);

  useEffect(() => {
    if (!projectId) return;

    const fetchChangesData = async () => {
      setLoadingChanges(true);
      setErrorChanges(null);

      try {
        await fetchDataWithRetries(`Search/${projectId}/changes?projectType=operations`, setChangesData);
      } catch (err) {
        setErrorChanges("فشل تحميل بيانات التغييرات.");
      } finally {
        setLoadingChanges(false);
      }
    };

    fetchChangesData();
  }, [projectId]);

  return (
    <>
    <Banner
        img={img}
        title="تقديم طلب العمليات"
        Description="قم بإضافة طلبك وتأكد من إدخال المعلومات بشكل صحيح"
      />

      <div className="flex justify-center flex-row-reverse items-start gap-5 flex-wrap">
        <div className="w-full lg:w-2/3">
          {loading ? (
            <div className="text-center text-blue-500 font-semibold animate-pulse">
              جاري تحميل البيانات...
            </div>
          ) : error ? (
            <div className="text-center text-red-500 font-bold">{error}</div>
          ) : (
            <Form userData={userData} apiData={apiData} isUpdate={!!projectId} />
          )}
        </div>

        {projectId && (
          <div className="">
            {loadingChanges ? (
              <div className="text-center text-blue-500 font-semibold animate-pulse">
                جاري تحميل بيانات التغييرات...
              </div>
            ) : errorChanges ? (
              <div className="text-center text-red-500 font-bold">{errorChanges}</div>
            ) : (
              <EngChangesRequest data={changesData} />
            )}
          </div>
        )}
      </div>
    </>
  );
}



import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import BranchTabs from "../../Component/BranceTaps/BranchTaps";
import SituationOptions from "../../util/Situation";

const CompletedUi = ({
  searchTerm,
  setSearchTerm,
  filteredProjects,
  setCompleted,
  noDataImage,
  office,
  setOffice,
  projectImage1,
  handleDelete,
}) => (
  <div className="container">
    <div className="search-container">
      <input
        type="text"
        placeholder="ابحث عن مشروع..."
        className="search-input"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <h4 className="w-[160px] text-[14px]">
        عدد الطلبات ( {filteredProjects.length} )
      </h4>
      <div
        onClick={() => setCompleted(null)}
        className="inline-flex flex-row-reverse gap-3 items-center border border-indigo-300 px-3 py-1.5 rounded-md text-indigo-500 hover:bg-indigo-50"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          ></path>
        </svg>
        <span className="ml-1 font-bold text-lg"> العوده</span>
      </div>
    </div>

    {filteredProjects.length === 0 && searchTerm && (
      <div className="NotFoundProject">
        <img
          src={noDataImage}
          alt="No data available"
          className="no-data-image"
        />
        <p>لا توجد مشاريع مطابقة لبحثك.</p>
      </div>
    )}
    <BranchTabs value={office} setOffice={setOffice} />

    <div className="w-full flex justify-center items-center mb-12"></div>

    {filteredProjects.length === 0 && !searchTerm && (
      <div className="NotFoundProject" dir="rtl">
        <img
          src={noDataImage}
          alt="No data available"
          className="no-data-image"
        />
        <p>لا يوجد بيانات للعرض</p>
      </div>
    )}

    <div className="cards-container">
      {filteredProjects.length > 0 &&
        filteredProjects.map((project, index) => (
          <div key={index} className="project-card !w-[300px]">
            <img
              src={
                project.modelPhotos && project.modelPhotos.length > 0
                  ? project.modelPhotos[0].url.toLowerCase().endsWith(".pdf")
                    ? projectImage1
                    : project.modelPhotos[0].url
                  : projectImage1
              }
              alt={project.faultType || "Project Image"}
              className="project-image"
            />
            <div className="project-info">
              <h3 className="project-title">{project.faultType || "مشروع"}</h3>
              <p> القسم: {project.type || ""} </p>
              <p>
                {" "}
                رقم امر العمل: {project.faultNumber || project.orderNumber}{" "}
              </p>
              <p>
                {" "}
                نوع امر العمل: {project.workOrderType ||
                  project.orderNumber}{" "}
              </p>
              <p> امر العمل: {project.orderType}</p>
              <p> الفرع: {project.branchName || project.orderNumber} </p>
              <p>
                موقف المشروع: {SituationOptions.get(project.situation).label}
              </p>
              <p> المكتب: {project.office} </p>
              <p>
                الحاله:
                {project.isArchived === true ? "تحت التنفيذ" : "تم التنفيذ"}
              </p>
              <p className="project-date">
                تاريخ استلام امر العمل:
                {new Date(project.receiveDateTime).toLocaleDateString()}
              </p>
              <p className="project-date"> الحي: {project.district} </p>
              <div className="buttonUpdeteDelete">
                <Link
                  to={
                    project.type === "الصيانه"
                      ? `/maintain-projects/${project.id}`
                      : project.type === "المشاريع الخاصة"
                      ? `/special-projects/${project.id}`
                      : project.type === "المشاريع"
                      ? `/request-projects/${project.id}`
                      : `/operations-maintenance/${project.id}`
                  }
                  className="view-project-button"
                >
                  تعديل الطلب
                </Link>
                <button
                  className="delete-project-button"
                  onClick={() =>
                    handleDelete(
                      project.faultNumber || project.orderNumber,
                      project.type
                    )
                  }
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
);

export default CompletedUi;

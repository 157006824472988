import React, { useState, useEffect, useMemo } from "react";
import * as XLSX from "xlsx";
import noDataImage from "../../Image/App Illustrations.jpg";
import img from "../../Image/microsoft-excel-icon 1.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./SearchRequests.css";
import axios from "axios";
import { Url } from "../../function/FunctionApi";
import { getCookie } from "../../Pages/Login/Login";
import { Link } from "react-router-dom";
import useExportToExcel from "../../hooks/ExportExcel";
import RequestCard from "../../Component/RequestCard/RequestCard";
import FilterationOrders from "../../Component/FilterationOrders/FilterationOrders";

// Modal component
const Modal = ({ isOpen, onClose, onExport }) =>
  isOpen && (
    <div className="modal-open">
      <div className="modal-content">
        <h2>تصدير البيانات</h2>
        <p>هل أنت متأكد أنك تريد تصدير الطلبات المحددة إلى Excel؟</p>
        <button className="export-button" onClick={onExport}>
          تصدير
        </button>
        <button className="close-modal-button" onClick={onClose}>
          إغلاق
        </button>
      </div>
    </div>
  );

const SearchRequests = () => {
  const [filters, setFilters] = useState({
    branch: "",
    requestNumber: "",
    contractor: "",
    situation: "",
    orderType: "",
    projectType: "",
    requestStatus: "",
    searchQuery: "",
    startDate: "",
    endDate: "",
    consultant: "",
    stationNumber: "",
    district: "",
  });

  const [apiData, setApiData] = useState([]);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userCookie = getCookie("user");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = JSON.parse(userCookie).token;
        const response = await axios.get(`${Url}Admin/AllOrders`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setApiData([
          ...(response.data.maintencesOrders || []),
          ...(response.data.privateProjects || []),
          ...(response.data.newProjects || []),
          ...(response.data.operationOrders || []),
        ]);
      } catch (err) {
        setError("فشل في تحميل البيانات، يرجى المحاولة لاحقاً.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userCookie]);

  const filteredRequests = useMemo(() => {
    return apiData.filter((request) => {
      const {
        branch,
        requestNumber,
        contractor,
        projectType,
        requestStatus,
        startDate,
        endDate,
        searchQuery,
        consultant,
        stationNumber,
        district,
        situation,
        orderType,
      } = filters;

      const matchesBranch =
        !branch || request.branchName?.toLowerCase() === branch.toLowerCase();
      const matchesRequestNumber =
        !requestNumber ||
        request.workOrderType?.toString().includes(requestNumber);
      const matchesContractor =
        !contractor ||
        request.contractor?.toLowerCase().includes(contractor.toLowerCase());
      const matchesProjectType =
        !projectType ||
        (typeof request.type === "string" &&
          request.type.toLowerCase().includes(projectType.toLowerCase()));
      const matchesRequestStatus =
        !requestStatus || request.isArchived?.toString() === requestStatus;
      const matchesConsultant =
        !consultant ||
        request.consultant?.toLowerCase().includes(consultant.toLowerCase());
      const matchesStationNumber =
        !stationNumber ||
        request.stationNumber?.toString().includes(stationNumber);
      const matchesDistrict =
        !district ||
        request.district?.toLowerCase().includes(district.toLowerCase());
      const matchesSituation =
        !situation ||
        request.situation?.toLowerCase().includes(situation.toLowerCase());

      const matchesOrderType =
        !orderType ||
        request.orderType?.toLowerCase().includes(orderType.toLowerCase());
      const matchesDate =
        (!startDate || new Date(request.orderDate) >= new Date(startDate)) &&
        (!endDate || new Date(request.orderDate) <= new Date(endDate));

      const matchesSearchQuery =
        !searchQuery ||
        Object.values(request).some((value) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );

      return (
        matchesBranch &&
        matchesRequestNumber &&
        matchesContractor &&
        matchesProjectType &&
        matchesRequestStatus &&
        matchesConsultant &&
        matchesStationNumber &&
        matchesDistrict &&
        matchesSituation &&
        matchesDate &&
        matchesSearchQuery &&
        matchesOrderType
      );
    });
  }, [apiData, filters]);

  console.log(filteredRequests);
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };
  const { exportToExcel } = useExportToExcel(
    filteredRequests,
    selectedRequests
  );
  const handleCheckboxChange = (index) => {
    setSelectedRequests((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleSelectAll = () => {
    setSelectedRequests(
      selectedRequests.length === filteredRequests.length
        ? []
        : filteredRequests.map((_, index) => index)
    );
  };

  const exportToExcelPrint = () => {
    exportToExcel();
    setIsModalOpen(false);
  };

  if (loading) return <Skeleton count={10} height={50} />;
  if (error) return <div>{error}</div>;

  const renderFilterOptions = (key) => {
    const optionsMap = {
      branch: [
        { value: "", label: "الفرع" },
        { value: "الرياض", label: "الرياض" },
        { value: "حائل", label: "حائل" },
      ],
      projectType: [
        { value: "", label: "نوع المشروع" },
        { value: "ordersSubs", label: "مشتركين" },
        { value: "operationOrders", label: "عمليات وصيانة" },
        { value: "newProjects", label: "مشاريع" },
      ],
      requestStatus: [
        { value: "", label: "حالة الطلب" },
        { value: "true", label: "مؤرشف" },
        { value: "false", label: "غير مؤرشف" },
      ],
    };
    return optionsMap[key] || [];
  };

  return (
    <div className="search-requests-container">
      <div className="filters-container">
        <div className="select-all-checkbox">
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={
                selectedRequests.length === filteredRequests.length &&
                filteredRequests.length > 0
              }
              onChange={handleSelectAll}
              className="custom-checkbox"
            />
          </div>
        </div>
        {Object.keys(filters).map(
          (key) =>
            key !== "searchQuery" && (
              <>
                <FilterationOrders
                  key={key}
                  name={key}
                  value={filters[key]}
                  onChange={handleFilterChange}
                  options={renderFilterOptions(key)}
                />
              </>
            )
        )}
        <h1>{filteredRequests.length}</h1>
      </div>
      <div className="cards-container">
        {filteredRequests.length > 0 ? (
          filteredRequests.map((request, index) => (
            <RequestCard
              key={index}
              request={request}
              isChecked={selectedRequests.includes(index)}
              onChange={() => handleCheckboxChange(index)}
            />
          ))
        ) : (
          <div className="changes-cards-container">
            <img style={{ width: "250px" }} src={noDataImage} alt="No Data" />
            <div>لا توجد الطلبات.</div>
          </div>
        )}
      </div>
      {selectedRequests.length > 0 && (
        <div className="div-open-modal">
          <button
            className="open-modal-button"
            onClick={() => setIsModalOpen(true)}
          >
            تصدير <img src={img} alt="Export to Excel" />
          </button>
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onExport={exportToExcelPrint}
      />
    </div>
  );
};

export default SearchRequests;

import React from "react";

function InputCoordinates({ change, value   }) { 

  return (
    <div className="group_fe flex gap-2 w-full flex-col">
      <label>الإحداثيات</label>
      <input
        type="text"
        name="Coordinates"
        placeholder="الإحداثيات"
        value={value || ""}
        onChange={change}
        required
      />
    </div>
  );
}

export default InputCoordinates;

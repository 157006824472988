import { useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import Card from "../../Component/DashoardContant/Cards/Card";
import { Bar } from "react-chartjs-2";
import { fetchDataWithRetries } from "../../function/FunctionApi";
import "./Dashboard.css";

function DashBoard() {
  const [ordersCountInRiyadhAndHail, setOrdersCountInRiyadhAndHail] = useState([]);
  const [projectInAllBranches, setProjectInAllBranches] = useState(null);
  const [statisticsOrders, setStatisticsOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchDataWithRetries(
          "Account/get-count-engineers-per-branch",
          setOrdersCountInRiyadhAndHail
        );

        await fetchDataWithRetries(
          "OrderForSubscribe/statistics-orders",
          setStatisticsOrders
        );

        await fetchDataWithRetries(
          "Account/get-count-projects-per-branch",
          setProjectInAllBranches
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Prepare chart data for `ordersCountInRiyadhAndHail`
  const chartData = {
    labels: ordersCountInRiyadhAndHail.map((item) => item.branchName),
    datasets: [
      {
        label: "عدد المهندسين لكل فرع",
        data: ordersCountInRiyadhAndHail.map((item) => item.engineersCount),
        backgroundColor: ["rgba(76, 175, 79, 1)", "rgba(54, 162, 235, 1)"],
        borderColor: ["rgba(46, 125, 50, 1)", "rgba(36, 123, 240, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "عدد المهندسين حسب الفرع" },
    },
    maintainAspectRatio: false,
  };

  // Prepare chart data for `projectInAllBranches`
  const chartDataProjects = {
    labels: projectInAllBranches?.data.map((item) => item.branchName),
    datasets: [
      {
        label: "عدد المشاريع لكل فرع",
        data: projectInAllBranches?.data.map((item) => item.projectCount),
        backgroundColor: ["rgba(76, 175, 79, 1)", "rgba(54, 162, 235, 1)"],
        borderColor: ["rgba(46, 125, 50, 1)", "rgba(36, 123, 240, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const chartOptionsProjects = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "عدد المشاريع حسب الفرع" },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="apDiv dashBoard">
      <Sidebar />
      <div className="body_container" dir="rtl">
        <div className="Header">
          <Header />
        </div>

        {/* Bar chart displayed at the top */}
        <div className="h-[500px]">
          <Bar data={chartData} options={chartOptions} />
        </div>

        <Card
          ordersCountInRiyadhAndHail={ordersCountInRiyadhAndHail}
          statisticsOrders={statisticsOrders}
        />
        
        <div className="h-[500px]">
          <Bar data={chartDataProjects} options={chartOptionsProjects} />
        </div>
      </div>
    </div>
  );
}

export default DashBoard;

import React, { useEffect, useState } from "react";
import axios from "axios";
import projectImage1 from "../../Image/Rectangle 34.png";
import noDataImage from "../../Image/App Illustrations.jpg";
import { Url } from "../../function/FunctionApi";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from "sweetalert2";
import "./Projects.css";
import { officeNameMap } from "../../util/officeConstants";
import Loading from "./Loading";
import CompletedUi from "./ProjectsCards";
import StatusSelection from "./StatusSelection";
const Projects = ({ userData }) => {
  const [completed, setCompleted] = useState();
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const isRiyadh = localStorage.getItem("IsRiyadh") === "true";
  const [office, setOffice] = useState("");
  const filteredProjectsToDisplay = () => {
    let filtered = projects;

    if (completed === true) {
      filtered = filtered.filter((project) => !project.isArchived);
    } else if (completed === false) {
      filtered = filtered.filter((project) => project.isArchived);
    }

    // if (isRiyadh) {
    //   filtered = filtered.filter((project) => project.Office === "Khurais");
    // } else {
    //   filtered = filtered.filter((project) => project.Office === "Hail");
    // }

    return filtered;
  };
  useEffect(() => {
    setFilteredProjects(filteredProjectsToDisplay());
  }, []);

  useEffect(() => {
    setSearchTerm("");
    setFilteredProjects(filteredProjectsToDisplay());
  }, [completed]);
  useEffect(() => {
    const filterProjects = () => {
      console.log(office);
      let filtered = projects;

      filtered = completed
        ? filtered.filter((project) => !project.isArchived)
        : filtered.filter((project) => project.isArchived);

      if (office === "الجميع") {
        return filtered;
      }
      filtered = filtered.filter((project) => project.office.includes(office));

      return filtered;
    };

    setFilteredProjects(filterProjects());
  }, [office]);
  console.log(filteredProjects);
  useEffect(() => {
    console.log(userData?.branchName);
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const apiUrl = "OrdersinHome/all-projects-inHome";
        const params = {
          branchName: userData?.branchName,
        };

        const response = await axios.get(`${Url}${apiUrl}`, { params });
        const projectData = [
          ...(response.data.MaintenanceProjects || []).map((project) => ({
            ...project,
            type: "الصيانه",
          })),
          ...(response.data.NewProjects || []).map((project) => ({
            ...project,
            type: "المشاريع",
          })),
          ...(response.data.OperationProjects || []).map((project) => ({
            ...project,
            type: "العمليات",
          })),
        ];

        setProjects(projectData);
        setFilteredProjects(projectData);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    if (userData && userData.branchName) {
      fetchProjects();
    }
  }, [userData]);

  useEffect(() => {
    const results = filteredProjects.filter((project) => {
      const searchTermLower = searchTerm.toLowerCase();
      const officeName = project.office || "";
      return (
        project.orderNumber
          ?.toString()
          .toLowerCase()
          .includes(searchTermLower) ||
        project.orderType.toLowerCase().includes(searchTermLower) ||
        project.faultNumber
          ?.toString()
          .toLowerCase()
          .includes(searchTermLower) ||
        project.appUserId?.toLowerCase().includes(searchTermLower) ||
        officeName.includes(searchTermLower) ||
        project.consultant?.toLowerCase().includes(searchTermLower) ||
        project.contractor?.toLowerCase().includes(searchTermLower) ||
        project.district?.toLowerCase().includes(searchTermLower) ||
        project.estimatedValue
          ?.toString()
          .toLowerCase()
          .includes(searchTermLower) ||
        project.extractNumber
          ?.toString()
          .toLowerCase()
          .includes(searchTermLower) ||
        project.faultType?.toLowerCase().includes(searchTermLower) ||
        project.office?.toLowerCase().includes(searchTermLower) ||
        project.orderCode?.toLowerCase().includes(searchTermLower) ||
        project.orderDate?.toLowerCase().includes(searchTermLower) ||
        project.orderType?.toLowerCase().includes(searchTermLower) ||
        project.projectType?.toLowerCase().includes(searchTermLower) ||
        project.resources?.toLowerCase().includes(searchTermLower) ||
        project.userName?.toLowerCase().includes(searchTermLower)
      );
    });

    setFilteredProjects(results);
  }, [searchTerm, projects, officeNameMap]);

  const handleDelete = async (projectId, projectType) => {
    console.log(projectType);
    const getProjectTypeRoute = (orderType) => {
      switch (orderType) {
        case "المشاريع الخاصة":
          return "privateproject";
        case "المشاريع":
          return "newproject";
        case "العمليات":
          return "operation";
        default:
          return "maintains";
      }
    };

    const projectTypeRoute = getProjectTypeRoute(projectType);

    const result = await Swal.fire({
      title: "هل أنت متأكد؟",
      text: "لا يمكنك التراجع عن هذا!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "نعم، احذف!",
      cancelButtonText: "لا، تراجع!",
    });

    if (result.isConfirmed) {
      try {
        const apiUrl = `Search/delete-by-orderidWithType?orderId=${projectId}&type=${projectTypeRoute}`;
        await axios.delete(`${Url}${apiUrl}`);
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.orderNumber !== projectId)
        );
        setFilteredProjects((prevFiltered) =>
          prevFiltered.filter((project) => project.orderNumber !== projectId)
        );
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.faultNumber !== projectId)
        );

        setFilteredProjects((prevFiltered) =>
          prevFiltered.filter((project) => project.faultNumber !== projectId)
        );

        Swal.fire({
          position: "center",
          icon: "success",
          title: "تم حذف الطلب بنجاح.",
          showConfirmButton: false,
          timer: 1500,
        });

        window.location.reload();
      } catch (err) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "حدث خطأ أثناء حذف الطلب.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  if (loading) {
    return <Loading searchTerm={searchTerm} setSearchTerm={setSearchTerm} />;
  }

  if (error) return <div>Error: {error}</div>;
  console.log(filteredProjects);

  return (
    <div className="latest-projects-container projects-page" dir="rtl">
      {completed === true ? (
        <CompletedUi
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filteredProjects={filteredProjects}
          setCompleted={setCompleted}
          noDataImage={noDataImage}
          office={office}
          setOffice={setOffice}
          projectImage1={projectImage1}
          handleDelete={handleDelete}
        />
      ) : completed === false ? (
        <CompletedUi
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filteredProjects={filteredProjects}
          setCompleted={setCompleted}
          noDataImage={noDataImage}
          office={office}
          setOffice={setOffice}
          projectImage1={projectImage1}
          handleDelete={handleDelete}
        />
      ) : (
        <StatusSelection setCompleted={setCompleted} />
      )}
    </div>
  );
};

export default Projects;

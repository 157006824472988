import React from "react";

function SelectSituation({ change, value }) {
  console.log("SelectSituation", value);

  return (
    <div className="group_fe w-full">
      <label>موقف التنفيذ</label>
      <select
        id="Situation"
        name="Situation"
        className="selectSituation"
        onChange={change}
        value={value || ""}
      >
        <option value="" disabled>
          اختر الحالة
        </option>
        <option value="pending">جاري</option>
        <option value="finish">منتهي</option>
        <option value="notFinished">لم يتم التنفيذ</option>

        <option value="contractor_delivered">
          تم تسليم المقاول (الأوامر التي تم وضع لها تاريخ تنفيذ)
        </option>
        <option value="contractor_received">
          تم الاستلام من المقاول (الأوامر التي تم وضع لها تاريخ تنفيذ)
        </option>
        <option value="certificate_issued">
          تم إصدار شهادة إنجاز (الأوامر التي تم وضع لها قيمة فعلية)
        </option>
        <option value="final_statement">
          منتهي مستخلص (الأوامر التي تم وضع لها رقم مستخلص)
        </option>
      </select>
    </div>
  );
}

export default SelectSituation;
